import { DESKTOP_NOTIFICATION_CODES } from "@config";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import EventService from "../services/event.service";
import notificationService from "../services/notification.service";
import { AppContext } from "./AppContext";

const NotificationContext = createContext();
const { Provider } = NotificationContext;

const message_beep = new Audio("/media/mp3/beep.mp3");
const reminder_beep = new Audio("/media/mp3/beep2.mp3");
const lead_beep = new Audio("/media/mp3/beep3.mp3");
const transaction_beep = new Audio("/media/mp3/beep4.mp3");
const document_beep = new Audio("/media/mp3/beep5.mp3");

const _AUDIOS = {
  "NEW.MESSAGE": message_beep,
  "FORM.SUBMITTED": message_beep,
  "NEW.LEAD": lead_beep,
  "LEAD.UPDATED": lead_beep,
  "LEAD.ASSIGNED": lead_beep,
  "LEAD.ASSIGNED_GROUP": lead_beep,
  "NEW.TRANSACTION": transaction_beep,
  "NEW.DOCUMENT": document_beep,
  "NEW.REMINDER": reminder_beep,
};

// Beep
const beep = (code) => {
  if (!_AUDIOS[code]) return;
  _AUDIOS[code]
    .play()
    .then()
    .catch((error) => console.log(error));
};

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const intl = useIntl();

  const {
    PM: { profile },
  } = useContext(AppContext);

  const navigate = useNavigate();

  // Handle Notification Click
  const _handleNotificationClick = useCallback((code, payload) => {
    setTimeout(() => {
      if (code === "NEW.MESSAGE") return navigate(`/messenger?conversation=${payload?.conversationID}&lead=${payload?.leadID}`);
      if (code === "NEW.LEAD") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "NEW.REMINDER") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "NEW.TRANSACTION") return navigate(`/leads/${payload?.leadID}/profile/transactions`);
      if (code === "NEW.DOCUMENT") {
        if (payload?.invoice) return navigate(`/leads/${payload?.leadID}/profile/invoices/${payload.invoice}`);
        if (payload?.offer) return navigate(`/leads/${payload?.leadID}/profile/offers/${payload.offer}`);
      }
      if (code === "LEAD.UPDATED") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "FORM.SUBMITTED") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "LEAD.ASSIGNED") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "LEAD.ASSIGNED_GROUP") return navigate(`/leads/${payload?.leadID}/profile/overview`);
    }, 0);
  }, []);

  // Render Web Notifications
  const renderWebNotification = (notification) => {
    /** */

    if (!notification) return console.log("No Notifications");
    if (!profile?.notification?.desktop?.enabled) return console.log("Notifications Disabled"); // Desktop Notifications Disabled
    if (!profile?.notification?.desktop?.subscriptions?.includes(notification.code)) return console.log("Notification Not Subscribed"); // Not Subscribed

    // Beep
    if (notification.beep) beep(notification.code);

    // Web Notifications (Only Render Thos with Display & Beep)
    if (notification.display && notification.beep) {
      const { code, display, payload } = notification;
      const n = new Notification(intl.formatMessage({ id: `NOTIF.${code}` }), {
        body: display?.name,
      });
      n.addEventListener("click", () => _handleNotificationClick(code, payload));
    }
    return;
  };

  // Hanlde notification Callback
  const _handleNotification = (payload = {}) => {
    notificationService.add(payload); // Add to Local Storage
    renderWebNotification(payload); // Render Web Notifications
    return setNotifications((x) => notificationService.getAll()); // Refresh Notifications State
  };

  //   Remove All Locally Persisted Notifications
  const _handleRemoveAll = () => {
    notificationService.removeAll();
    return setNotifications(() => []);
  };

  // Set Notificationa as "SEEN"
  const _handleSeen = (UUID) => {
    notificationService.seen(UUID);
    return setNotifications(() => notificationService.getAll());
  };

  // Register Listeners
  useEffect(() => {
    setNotifications(() => notificationService.getAll());
    EventService.on(DESKTOP_NOTIFICATION_CODES, _handleNotification);
    return () => {
      EventService.off(DESKTOP_NOTIFICATION_CODES, _handleNotification);
    };
  }, []);

  return (
    <Provider
      value={{
        notifications,
        methods: {
          removeAll: _handleRemoveAll,
          seen: _handleSeen,
          click: _handleNotificationClick,
        },
      }}
    >
      {children}
    </Provider>
  );
};
export { NotificationContext, NotificationProvider };
